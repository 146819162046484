import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Table, Button, Modal, Form, Input, Space, Popconfirm, message, Select, Col, Row, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, FileDoneOutlined, SearchOutlined, UserOutlined, BookOutlined, ArrowLeftOutlined, ArrowRightOutlined, FolderOpenOutlined } from '@ant-design/icons';
import ParticipantesExport from './ParticipantesExport'; // Adjust the path if necessary
import { getUsuarios, createUsuario, updateUsuario, deleteUsuario } from '../../../../services/Producto_3/Fort_Capacidades/Participantes/cursoFortalecimientoUserService';
import { getDepartamentos, getMunicipios, getEtnias, getRangosEdad, getParticipaciones, getSubregion } from '../../../../services/Producto_3/Fort_Capacidades/Participantes/cursoFortalecimientoServiceDatos';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../../../store/auth/selectors';



import './TablaUsuarios.css';
import SesionCF from './SesionCF';
const { Option } = Select;

const TablaUsuarios = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [form] = Form.useForm();
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [etnias, setEtnias] = useState([]);
    const [rangosEdad, setRangosEdad] = useState([]);
    const [participaciones, setParticipaciones] = useState([]);
    const [subregionData, setSubregionData] = useState([]);
    const [filteredMunicipios, setFilteredMunicipios] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    const [filterDepartamento, setFilterDepartamento] = useState(null);
    const [filterMunicipio, setFilterMunicipio] = useState(null);
    const [filterSubSesion, setFilterSubSesion] = useState(null);
    const [filterCedula, setFilterCedula] = useState("");
    const [isModalVisibleSesion, setIsModalVisibleSesion] = useState(false);
    const [participante_id, setParticipante_id] = useState(null);




    const childRef = useRef(null);
    const appName = 'curso-fortalecimiento';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionInsert = permissions.priv_insert === 'Y';
    //const permissionExport = permissions.priv_export === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionDelete = permissions.priv_delete === 'Y';

    const fetchUsuarios = async () => {
        setLoading(true);
        try {
            const users = await getUsuarios();
            console.log("users", users);
            setData(users);
        } catch (error) {
            message.error('Error al cargar los usuarios');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateSesiones = (userId, newSessionCount) => {
        const updatedData = data.map(user =>
            user.oid === userId ? { ...user, totalU: newSessionCount } : user
        );
        setData(updatedData);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [departamentosData, municipiosData, etniasData, rangosEdadData, participacionesData, subregionData] = await Promise.all([
                    getDepartamentos(),
                    getMunicipios(),
                    getEtnias(),
                    getRangosEdad(),
                    getParticipaciones(),
                    getSubregion(),
                ]);
                setDepartamentos(departamentosData);
                setMunicipios(municipiosData);
                setEtnias(etniasData);
                setRangosEdad(rangosEdadData);
                setParticipaciones(participacionesData);
                setSubregionData(subregionData);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };
        fetchData();
    }, []);

    const handleDepartamentoChange = (value) => {
        setFilterDepartamento(value);
        const filtered = municipios.filter(municipio => municipio.oid_depto === value);
        setFilteredMunicipios(filtered);
        setFilterMunicipio(null);
    };

    useEffect(() => {
        if (departamentos.length && municipios.length && etnias.length && rangosEdad.length) {
            fetchUsuarios();
        }
    }, [departamentos, municipios, etnias, rangosEdad]);

    useEffect(() => {
        if (editingUser) {
            const departamento = departamentos.find(dep => dep.departamento === editingUser.departamento)?.oid;
            const municipio = municipios.find(mun => mun.nombremunicipio === editingUser.municipio)?.oid;
            const filtered = municipios.filter(municipio => municipio.oid_depto === departamento);
            setFilteredMunicipios(filtered);
            form.setFieldsValue({
                id_departamento: departamento,
                id_municipio: municipio,
                ...editingUser
            });
        }
    }, [editingUser, departamentos, municipios, form]);

    const showModal = () => {
        setEditingUser(null);
        form.resetFields();
        setIsModalVisible(true);
    };

    const showModalSesion = (user) => {
        setEditingUser(user);
        setIsModalVisibleSesion(true);
    };

    const handleCancelSesion = () => {
        setIsModalVisibleSesion(false);
        setEditingUser(null);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const dataToSend = {
                oid: editingUser ? editingUser.oid : undefined,
                departamento: values.id_departamento,
                municipio: values.id_municipio,
                nombre_participante: values.nombre_participante,
                documento: values.documento,
                genero: values.genero_id,
                etnia: values.id_etnia,
                campesino: values.campesino_id,
                victima: values.victima_id,
                discapacitado: values.discapacitado_id,
                rango_edad: values.id_rango_edad,
                instancia: values.id_instancia,
                participa: values.participacion_id
            };
            if (editingUser) {
                await updateUsuario(editingUser.oid, dataToSend);
                message.success('Usuario actualizado correctamente');
            } else {
                await createUsuario(dataToSend);
                message.success('Usuario creado correctamente');
            }
            fetchUsuarios();
            handleCancel();
            setIsModalVisible(false);
        } catch (error) {
            message.error('Error al guardar el usuario');
            console.error('Error:', error);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setActiveTab('1');
        form.resetFields();
        if (childRef.current) {
            childRef.current.resetFormFields();
        }
    };

    const handleEdit = (user) => {
        setEditingUser(user);
        form.setFieldsValue(user);
        setIsModalVisible(true);
        form.setFieldsValue({
            municipio: user.nombremunicipio,
        });
    };

    const handleDelete = async (oid) => {
        try {
            await deleteUsuario(oid);
            message.success('Participante eliminado correctamente');
            fetchUsuarios();
        } catch (error) {
            message.error('Error al eliminar el participante, tiene sesiones asociadas');
        }
    };

    const columns = [
        {
            title: 'Sub-Región',
            dataIndex: 'regional',
            key: 'regional',
            render: text => text.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        },
        {
            title: 'Departamento',
            dataIndex: 'departamento',
            key: 'departamento',
        },
        {
            title: 'Municipio',
            dataIndex: 'municipio',
            key: 'municipio',
        },
        {
            title: 'Participante',
            dataIndex: 'nombre_participante',
            key: 'nombre_participante',
        },
        {
            title: 'Documento',
            dataIndex: 'documento',
            key: 'documento',
        },
        {
            title: 'Género',
            dataIndex: 'genero',
            key: 'genero',
            className: 'column-genero',
        },
        {
            title: 'Etnia',
            dataIndex: 'etnia',
            key: 'etnia',
            className: 'column-etnia',
        },
        {
            title: 'Edad',
            dataIndex: 'rango_edad',
            key: 'rango_edad',
            className: 'column-edad',
        },
        {
            title: '# Sesiones',
            dataIndex: 'totalU',
            key: 'totalU',
            className: 'column-edad',
            align: 'center'
        },
        {
            title: 'Acciones',
            key: 'acciones',
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    {permissionUpdate && (
                        <Tooltip title="Editar participante">
                            <Button type="primary" icon={<EditOutlined />} style={{ backgroundColor: '#f0ab18', color: '#ffffff' }} onClick={() => handleEdit(record)} />
                        </Tooltip>
                    )} {permissionDelete && (
                        <Tooltip title="Eliminar participante">
                            <Popconfirm
                                title="¿Estás seguro de eliminar este usuario?"
                                onConfirm={() => handleDelete(record.oid)}
                                okText="Sí"
                                cancelText="No"
                            >
                                <Button type="primary" danger icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    )}{permissionInsert && (
                        <Tooltip title="Agregar Sesiones">
                            <Button type="primary" icon={<FileDoneOutlined />} onClick={() => showModalSesion(record)} />
                        </Tooltip>
                    )}
                </Space>
            ),
        }
    ];

    const filteredData = data.filter(user => {
        return (

            (!filterDepartamento || user.id_departamento === filterDepartamento) &&
            (!filterMunicipio || user.id_municipio === filterMunicipio) &&
            (!filterSubSesion || user.regional_id === filterSubSesion) &&
            (!filterCedula || (user.documento && user.documento.toString().includes(filterCedula.toString())))
        );
    });

    const handleClearFilters = () => {
        setActiveTab('1');
        setFilterSubSesion(null);
        setFilterDepartamento(null);
        setFilterMunicipio(null);
        setFilterCedula("");
        form.resetFields();
        fetchUsuarios();
    };

    const handleTabChange = async (key) => {
        try {
            await form.validateFields();
            setActiveTab(key);
            setParticipante_id(editingUser);
        } catch (error) {
            message.error('Por favor completa todos los campos');
            console.error('Error:', error);
            return;
        }

    };

    const handleNext = async () => {
        try {
            const values = await form.validateFields();

            // Guardamos el registro sin cerrar el modal
            const dataToSend = {
                oid: editingUser ? editingUser.oid : undefined,
                departamento: values.id_departamento,
                municipio: values.id_municipio,
                nombre_participante: values.nombre_participante,
                documento: values.documento,
                genero: values.genero_id,
                etnia: values.id_etnia,
                campesino: values.campesino_id,
                victima: values.victima_id,
                discapacitado: values.discapacitado_id,
                rango_edad: values.id_rango_edad,
                instancia: values.id_instancia,
                participa: values.participacion_id
            };
            let respuesta = null;
            if (editingUser) {
                respuesta = await updateUsuario(editingUser.oid, dataToSend);
                message.success('Usuario actualizado correctamente');
            } else {
                respuesta = await createUsuario(dataToSend); // Aquí es donde el `oid` del registro nuevo será recibido
                message.success('Usuario creado correctamente');
            }
            const oid = editingUser ? editingUser.oid : respuesta.insertId;
            const dataToSendWithIds = {
                ...dataToSend,
                oid,
                id_departamento: dataToSend.departamento,
                id_municipio: dataToSend.municipio,
            };
            setEditingUser(dataToSendWithIds);
            setParticipante_id(dataToSendWithIds);
            fetchUsuarios();
            // Cambiar la pestaña activa
            setActiveTab('2'); // O cualquier otra lógica para cambiar la pestaña
        } catch (error) {
            message.error('Por favor completa todos los campos');
            console.error('Error:', error);
        }
    };


    const handleBack = () => {
        if (activeTab === '2') {
            setActiveTab('1');
        }
    };

    const tabItems = [
        {
            key: "1",
            label: <span><UserOutlined /> Datos Generales</span>,
            children: (
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="nombre_participante"
                                label="Nombre"
                                rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
                            >
                                <Input placeholder="Ingresa el nombre del participante" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="documento"
                                label="Documento"
                                rules={[
                                    { required: true, message: 'Por favor ingrese el documento' }
                                ]}
                            >
                                <Input
                                    placeholder="Ingresa el numero de documento"
                                    onInput={(e) => {
                                        // Reemplaza todo lo que no sea un número
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                    }}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="id_departamento"
                                label="Departamento"
                                rules={[{ required: true, message: 'Por favor selecciona un departamento' }]}
                            >
                                <Select onChange={handleDepartamentoChange} placeholder="Selecciona un departamento">
                                    {departamentos.map(departamento => (
                                        <Option key={departamento.oid} value={departamento.oid}>
                                            {departamento.departamento}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="id_municipio"
                                label="Municipio"
                                rules={[{ required: true, message: 'Por favor selecciona un municipio' }]}
                            >
                                <Select placeholder="Selecciona un municipio">
                                    {filteredMunicipios.map(municipio => (
                                        <Option key={municipio.oid} value={municipio.oid}>
                                            {municipio.nombremunicipio}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="id_etnia"
                                label="Etnia"
                                rules={[{ required: true, message: 'Por favor seleccione una etnia' }]}
                            >
                                <Select placeholder="Seleccione una etnia">
                                    {etnias.map(etnia => (
                                        <Option key={etnia.oid} value={etnia.oid}>{etnia.nombre}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="id_rango_edad"
                                label="Rango de Edad"
                                rules={[{ required: true, message: 'Por favor seleccione un rango de edad' }]}
                            >
                                <Select placeholder="Seleccione un rango de edad">
                                    {rangosEdad.map(rango => (
                                        <Option key={rango.oid} value={rango.oid}>{rango.rango}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="id_instancia"
                                label="Participación"
                                rules={[{ required: true, message: 'Por favor seleccione si participa' }]}
                            >
                                <Select placeholder="Seleccione participación">
                                    {participaciones.map(participacion => (
                                        <Option key={participacion.oid} value={participacion.oid}>{participacion.nombre}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="genero_id"
                                label="Género"
                                rules={[{ required: true, message: 'Por favor seleccione un género' }]}
                            >
                                <Select placeholder="Seleccione un género">
                                    <Option value="1">Mujer</Option>
                                    <Option value="2">Hombre</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="campesino_id"
                                label="Campesino"
                                rules={[{ required: true, message: 'Por favor seleccione si es campesino' }]}
                            >
                                <Select placeholder="¿Es campesino?">
                                    <Option value="1">Sí</Option>
                                    <Option value="2">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="victima_id"
                                label="Víctima"
                                rules={[{ required: true, message: 'Por favor seleccione si es víctima' }]}
                            >
                                <Select placeholder="¿Es víctima?">
                                    <Option value="1">Sí</Option>
                                    <Option value="2">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="discapacitado_id"
                                label="discapacitado"
                                rules={[{ required: true, message: 'Por favor seleccione si es discapacitado' }]}
                            >
                                <Select placeholder="¿Es discapacitado?">
                                    <Option value="1">Sí</Option>
                                    <Option value="2">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="participacion_id"
                                label="¿Representa el/la participante a la comunidad en esta sesión?"
                                rules={[{ required: true, message: 'Por favor seleccione si el/la participante representa a la comunidad en esta sesión.' }]}
                            >
                                <Select placeholder="Seleccione una opción">
                                    <Option value="1">Sí</Option>
                                    <Option value="2">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            ),
        },
        {
            key: "2",
            label: <span><BookOutlined /> Registrar Asistencia</span>,
            children: <SesionCF ref={childRef} user={participante_id} onCancel={handleCancelSesion} onUpdateSessions={handleUpdateSesiones} />,
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [departamentosData, municipiosData] = await Promise.all([
                    getDepartamentos(),
                    getMunicipios(),
                ]);
                setDepartamentos(departamentosData);
                setMunicipios(municipiosData);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };
        fetchData();
    }, []);


    return (
        <div className="container">
            <div className="filtro-container">
                <Row gutter={1}>
                    <Col span={3.5}>
                        <Select
                            showSearch
                            placeholder="Sub-Región"
                            value={filterSubSesion || undefined}
                            onChange={(value) => setFilterSubSesion(value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        >
                            {subregionData.map(subRegion => (
                                <Option key={subRegion.oid} value={subRegion.oid}>{subRegion.nombre_subregion_pdet}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={4.5}>
                        <Select
                            showSearch
                            placeholder="Filtrar por Departamento"
                            value={filterDepartamento || undefined}
                            onChange={handleDepartamentoChange}
                            onSearch={(value) => setFilterDepartamento(value)}
                            className="filtro-item"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {departamentos.map(depto => (
                                <Option key={depto.oid} value={depto.oid}>{depto.departamento}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={3.1}>
                        <Select
                            showSearch
                            placeholder="Filtrar por Municipio"
                            value={filterMunicipio || undefined}
                            onChange={(value) => setFilterMunicipio(value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {filteredMunicipios.map(mun => (
                                <Option key={mun.oid} value={mun.oid}>{mun.nombremunicipio}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={3.1}>
                        <Input
                            placeholder="Buscar por Cédula"
                            value={filterCedula}
                            onChange={(e) => setFilterCedula(e.target.value)}
                            className="filtro-item"
                            style={{ height: 32 }}
                        />
                    </Col>
                    <Col span={2.1} className="col-limpiar-filtros">
                        <Tooltip title="Limpiar filtros">
                            <Button
                                icon={<SearchOutlined style={{ color: '#000000 !important' }} />}
                                onClick={handleClearFilters}
                                style={{
                                    backgroundColor: '#1677FF',
                                    color: '#ffffff'
                                }}
                            >
                                Limpiar Filtros
                            </Button>
                        </Tooltip>
                    </Col>
                    {permissionInsert && (
                        <Col span={2.1}>
                            <Tooltip title="Crear Participantes">
                                <Button
                                    icon={<PlusOutlined />}
                                    onClick={showModal}
                                    style={{
                                        backgroundColor: '#3BB44A',
                                        color: '#ffffff'
                                    }}
                                >
                                    Crear Participante
                                </Button>
                            </Tooltip>
                        </Col>
                    )}
                    <Col>
                        <ParticipantesExport dataSource={filteredData} />
                    </Col>
                </Row>
            </div>
            <div className="participant-count-container">
                <h1 className="participant-count-title">
                    Número de Participantes: <span className="participant-count-number">{filteredData.length}</span>
                </h1>
            </div>
            <div className="tabla-container">
                <Table
                    className="table-curso-fortalecimiento"
                    columns={columns}
                    dataSource={filteredData}
                    rowKey="oid"
                    loading={loading}
                    pagination={{ pageSize: 10 }}
                    scroll={{ x: 'max-content' }}
                    locale={{
                        emptyText: filteredData.length === 0 ? 'No se han registrado usuarios aún' : 'No hay usuarios disponibles en este momento',
                    }}
                />
            </div>
            <Modal
                title={<div style={{ textAlign: 'center' }}>{editingUser ? 'Editar Participante' : 'Crear Participante'}</div>}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
                footer={null}
            >
                <div>
                    <Tabs
                        activeKey={activeTab}
                        onChange={handleTabChange}
                        type="card"
                        size="large"
                        tabBarStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        items={tabItems}
                    />
                    <div style={{ textAlign: 'center', marginTop: 16 }}>
                        <Button
                            type="default"
                            onClick={handleBack}
                            style={{ marginRight: 8 }} // Espaciado entre los botones
                            disabled={activeTab === '1'} // Deshabilitar el botón Atrás si estamos en el primer tab
                            icon={<ArrowLeftOutlined />} // Icono de Atrás
                        >
                            Atrás
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleNext}
                            style={{ marginLeft: 8 }} // Espaciado entre los botones
                            icon={<ArrowRightOutlined />} // Icono de Siguiente
                        >
                            {activeTab === '1' ? 'Siguiente' : 'Registrar'}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                title={'Sesiones'}
                open={isModalVisibleSesion}
                width={1000}
                onCancel={handleCancelSesion}
                footer={null}
            >
                <SesionCF ref={childRef} user={editingUser} onCancel={handleCancelSesion} onUpdateSessions={handleUpdateSesiones} />
            </Modal>

        </div>
    );
};

export default TablaUsuarios;
