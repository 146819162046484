import React from "react";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const ParticipantesExport = ({ dataSource }) => {
    const exportToExcel = async () => {
        if (!dataSource || dataSource.length === 0) {
            console.warn("No hay datos para exportar.");
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Participantes");

        // Definir encabezados
        const headers = [
            "Sub-Región",
            "Departamento",
            "Municipio",
            "Participante",
            "Documento",
            "Género",
            "Etnia",
            "Edad",
            "# Sesiones"
        ];

        // Agregar encabezados a la hoja de cálculo
        worksheet.addRow(headers);

        // Aplicar estilos a las cabeceras
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFF" } };
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "338bc7" } };
            cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
            cell.border = {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } }
            };
        });

        // Agregar datos a la hoja de cálculo
        dataSource.forEach((row) => {
            worksheet.addRow([
                row.regional ? row.regional.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : "",
                row.departamento || "",
                row.municipio || "",
                row.nombre_participante || "",
                row.documento || "",
                row.genero || "",
                row.etnia || "",
                row.rango_edad || "",
                row.totalU || 0
            ]);
        });

        // Aplicar estilos de alineación y bordes a todas las celdas de contenido
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) return; // Saltamos la cabecera

            row.eachCell((cell, colNumber) => {
                let alignment = "left";
                
                // Centrar columnas numéricas y de documento
                if (colNumber === 5 || colNumber === 9) { // Documento y # Sesiones
                    alignment = "center";
                }

                cell.alignment = { horizontal: alignment, vertical: "middle", wrapText: true };
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } }
                };
            });
        });

        // Ajustar automáticamente el ancho de las columnas
        worksheet.columns.forEach((column, index) => {
            let maxLength = headers[index].length;
            
            worksheet.eachRow({ includeEmpty: true }, (row) => {
                const cell = row.getCell(index + 1);
                let cellLength = 0;
                
                if (cell.value) {
                    cellLength = cell.value.toString().length;
                }
                
                if (cellLength > maxLength) {
                    maxLength = cellLength;
                }
            });
            
            // Ancho mínimo de 10 y máximo de 50
            column.width = Math.min(Math.max(maxLength + 2, 10), 50);
        });

        // Guardar archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "Participantes.xlsx");
    };

    return (
        <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={exportToExcel}
            style={{ backgroundColor: "#28a745", borderColor: "#218838", color: "white" }}
        >
            Exportar a Excel
        </Button>
    );
};

export default ParticipantesExport;