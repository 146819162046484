import React from "react";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const InstanciasExport = ({ dataSource }) => {
    // Función para convertir valores booleanos a texto (similar a resultadoInfo)
    const formatBooleanValue = (value) => {
        if (value === 1 || value === true) return "Sí";
        if (value === 2 || value === false) return "No";
        return "Sin información";
    };

    const exportToExcel = async () => {
        if (!dataSource || dataSource.length === 0) {
            console.warn("No hay datos para exportar.");
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Instancias");

        // Definir encabezados (simplificados para Excel)
        const headers = [
            "Sub-Región",
            "Departamento",
            "Municipio",
            "Momento",
            "Nombre de la instancia",
            "¿El municipio cuenta con la instancia?",
            "¿Está activa la instancia?",
            "Tipo de instancia",
            "Estado de formalidad de la instancia",
            "Tipo de norma",
            "Número y año",
            "¿Tiene prioridades de trabajo en los pilares 6 y 7?",
            "¿Cuenta con reglamento interno la instancia?",
            "¿Cuenta con un instrumento de planeación que operativiza la instancia?",
            "Temas que se incluye en el instrumento de planeación",
            "Periodicidad en que se realiza el instrumento de planeación",
            "¿Se hace seguimiento al instrumento de planeación?",
            "¿La instancia cuenta con procesos de aprendizaje e intercambio?",
            "¿Hay articulación con otras instancias para la gestión?",
            "¿Las políticas públicas implementadas se alinean con las políticas nacionales?",
            "¿La instancia incorpora en su planeación objetivos/acciones/metas?",
            "Instrumento de planeación",
            "¿Representantes sociales son integrantes permanentes con voz y voto?",
            "Porcentaje de la participación con voz y voto",
            "Número de actores participante por acto normativo",
            "Trabajo articulado y comunicación fluída",
            "¿La instancia tiene mecanismos para garantizar la participación?",
            "Necesidades sentidas de acompañamiento",
            "Lecciones Aprendidas - Misión 1",
            "Lecciones Aprendidas - Misión 2",
            "Lecciones Aprendidas - Misión 3"
        ];

        // Agregar encabezados a la hoja de cálculo
        worksheet.addRow(headers);

        // Aplicar estilos a las cabeceras
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFF" } };
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "338bc7" } };
            cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
            cell.border = {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } }
            };
        });

        // Agregar datos a la hoja de cálculo
        dataSource.forEach((row) => {
            worksheet.addRow([
                row.regional ? row.regional.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ") : "",
                row.departamento || "",
                row.municipio || "",
                row.momento || "",
                row.nombre_instancia || "",
                formatBooleanValue(row.instancia_muni),
                formatBooleanValue(row.instancia_estado),
                row.tipo_instancia || "",
                formatBooleanValue(row.instancia_formalidad),
                row.tipo_norma || "",
                row.numero_anual || "",
                formatBooleanValue(row.trabajo_pilar),
                formatBooleanValue(row.instancia_reglamento),
                formatBooleanValue(row.instancia_planeacion),
                row.planeacion || "",
                row.periodicidad || "",
                formatBooleanValue(row.seguimiento),
                formatBooleanValue(row.intercambio),
                formatBooleanValue(row.articulacion),
                formatBooleanValue(row.politicas),
                formatBooleanValue(row.metas),
                row.instrumento || "",
                formatBooleanValue(row.voz_voto),
                row.porcentaje_voz_voto ? `${row.porcentaje_voz_voto}%` : "0%",
                row.acto_normativo || "",
                formatBooleanValue(row.comunicacion),
                formatBooleanValue(row.participacion),
                row.acompanamiento || "",
                row.mision1 || "",
                row.mision2 || "",
                row.mision3 || ""
            ]);
        });

        // Aplicar estilos de alineación y bordes a todas las celdas de contenido
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) return; // Saltamos la cabecera

            row.eachCell((cell, colNumber) => {
                let alignment = "left";
                
                // Centrar columnas booleanas y numéricas
                if ([5, 6, 8, 11, 12, 13, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26].includes(colNumber)) {
                    alignment = "center";
                }

                cell.alignment = { 
                    horizontal: alignment, 
                    vertical: "middle", 
                    wrapText: true 
                };
                
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } }
                };
            });
        });

        // Ajustar automáticamente el ancho de las columnas
        worksheet.columns.forEach((column, index) => {
            let maxLength = headers[index].length;
            
            worksheet.eachRow({ includeEmpty: true }, (row) => {
                const cell = row.getCell(index + 1);
                let cellLength = 0;
                
                if (cell.value) {
                    cellLength = cell.value.toString().length;
                }
                
                if (cellLength > maxLength) {
                    maxLength = cellLength;
                }
            });
            
            // Ancho mínimo de 10 y máximo de 50 (excepto para columnas de texto largo)
            const isLongTextColumn = [27, 28, 29, 30].includes(index + 1); // Columnas con texto largo
            column.width = isLongTextColumn ? 50 : Math.min(Math.max(maxLength + 2, 10), 30);
        });

        // Guardar archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "Instancias_Municipales.xlsx");
    };

    return (
        <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={exportToExcel}
            style={{ backgroundColor: "#28a745", borderColor: "#218838", color: "white" }}
        >
            Exportar a Excel
        </Button>
    );
};

export default InstanciasExport;