import React from "react";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const AcompanamientoOrgExport = ({ dataSource }) => {

    const exportToExcel = async () => {
        if (!dataSource || dataSource.length === 0) {
            console.warn("No hay datos para exportar.");
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("AcompañamientoOrg");

        // Definir columnas a combinar (merge)
        const columnsToMerge = ["Proyecto", "Sub-Región", "Departamento", "Municipio", "Zona", "Línea Productiva", "Organización", "Mes / Año",
            "Día", "Acompañamiento Técnico", "Capacitación", "Total", "Temáticas",
            "Plan de Acompañamiento", "N° Planes Acompañamiento", "Reunión Socialización", "N° Reuniones Socialización",
            "Plan de Compras", "N° Planes Compras"];

        // Definir encabezados
        const headers = [
            "Proyecto", "Sub-Región", "Departamento", "Municipio", "Zona", "Línea Productiva", "Organización", "Mes / Año",
            "Día", "Acompañamiento Técnico", "Capacitación", "Total", "Temáticas",
            "Plan de Acompañamiento", "N° Planes Acompañamiento", "Reunión Socialización", "N° Reuniones Socialización",
            "Plan de Compras", "N° Planes Compras"
        ];

        // Agregar encabezados a la hoja de cálculo
        worksheet.addRow(headers);

        // Aplicar estilos a las cabeceras
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFF" } };
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "338bc7" } };
            cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
            cell.border = {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } }
            };
        });

        // Agregar datos a la hoja de cálculo
        const exportData = dataSource.map((row) => [
            row.proyecto, row.subregion, row.departamento, row.municipio, row.zona, row.linea_p, row.organizacion, row.mes,
            row.dia, row.acompanamiento, row.capacitacion, row.totalPorRegistro, row.tematica,
            row.plan_acompa_obs, row.plan_acompa_num, row.reunion_socializa_obs, row.reunion_socializa_num,
            row.plan_compras_obs, row.plan_compras_num
        ]);

        exportData.forEach((dataRow) => {
            worksheet.addRow(dataRow);
        });

        // Aplicar estilos de alineación y bordes a todas las celdas de contenido
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) return; // Saltamos la cabecera

            row.eachCell((cell, colNumber) => {
                let alignment = "left";

                // Aplicar alineación específica por columna
                if (["Zona", "Acompañamiento Técnico", "Capacitación", "Total"].includes(headers[colNumber - 1])) {
                    alignment = "center";
                }

                cell.alignment = { horizontal: alignment, vertical: "middle", wrapText: true };

                // Aplicar bordes a todas las celdas
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } }
                };
            });
        });

        // Aplicar combinación de celdas según las columnas a mergear
        columnsToMerge.forEach((col, index) => {
            let startRow = 2; // Comenzar en la segunda fila después del encabezado
            let lastValue = worksheet.getCell(startRow, index + 1).value;
            for (let i = 3; i <= worksheet.rowCount; i++) {
                let currentValue = worksheet.getCell(i, index + 1).value;
                //Emcabezado del 1 al 7 y del 12         
                if ((index + 1) < 7 || (index + 1) === 12) {
                    currentValue = worksheet.getCell(i, 7).value;
                    lastValue = worksheet.getCell(startRow, 7).value;
                }
                //emcabezado del 12
                else if((index + 1) > 12){
                    currentValue = worksheet.getCell(i, 8).value;
                    lastValue = worksheet.getCell(startRow, 8).value;
                }
                // Comprobar si el valor actual es diferente al último valor
                if (currentValue !== lastValue) {
                    if ((index + 1) < 9 || (index + 1) > 11) {
                        if (i - startRow > 1) {
                            worksheet.mergeCells(startRow, index + 1, i - 1, index + 1);
                        }
                    }
                    startRow = i;
                    lastValue = currentValue;
                }
            }
            
            if ((index + 1) < 9 || (index + 1) > 11) {
                if (worksheet.rowCount - startRow > 0) {
                    worksheet.mergeCells(startRow, index + 1, worksheet.rowCount, index + 1);
                }
            }
        });

        // Ajustar automáticamente el ancho de las columnas
        worksheet.columns.forEach((column, index) => {
            column.width = headers[index].length + 5; // Ajustar ancho según el contenido
        });

        // Guardar archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "AcompañamientoOrg.xlsx");
    };

    return (
        <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={exportToExcel}
            style={{ backgroundColor: "#28a745", borderColor: "#218838", color: "white" }}
        >
            Exportar a Excel
        </Button>
    );
};

export default AcompanamientoOrgExport;
